body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #007bff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-autosuggest__container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
}

.react-autosuggest__input {
  width: 100%;
  height: 100%;
  font-size: 24px;
  flex: 1;
  color: #DDDDDD;
  background-color: #070707;
  border: 0;
}

.react-autosuggest__input::placeholder {
  color: #fff;
  opacity: 1;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  position: absolute;
  padding: 0;
  background-color: #070707;
  list-style-type: none;
  border: 1px solid #505050;
  z-index: 10;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 6px;
  white-space: nowrap;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #070707;
}

.result {
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: space-between;
  font-size: 100%;
}

.shortCode {
  background-color: #4696ec;
  color: #fff;
  padding: 4px;
  border-radius: 2px;
}

.highlight {
  background-color: yellow; 
  padding: 4px;
  border-radius: 2px;
}

.magnifier-image {
  max-width: 40em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}